import { Message, TextMessage, isTextMessage } from '@/lib/chat/types'
import { useMemo } from 'react'
import { EditableBubble } from '../EditableBubble'
import cn from 'clsx'
import DOMPurify from 'isomorphic-dompurify'
import { Bubble } from '../Bubble'
import { EditButton } from '../EditButton'

type UserMessagePropTypes = {
  message: Message
  index: number
  isUserEditing: boolean
  showPenIcon: (_message: TextMessage) => boolean
}

export const UserMessageBox = ({
  message,
  isUserEditing,
  showPenIcon,
}: UserMessagePropTypes) => {
  const isEditing = useMemo(
    () => isTextMessage(message) && isUserEditing && message.editable,
    [isUserEditing, message],
  )
  if (!isTextMessage(message)) {
    return null
  }
  return (
    <div className="flex mr-2 sm:mr-4">
      {isEditing && <EditableBubble messageText={message.text} />}
      {!isEditing && (
        <div className={cn(showPenIcon(message) ? '' : 'mr-2 sm:mr-4')}>
          <Bubble sender={message.sender}>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(message.text),
              }}
            />
          </Bubble>
        </div>
      )}
      {showPenIcon(message) && (
        <div className="h-full flex flex-col justify-center">
          <EditButton isUserEditing={false} className="hover:bg-gray" />
        </div>
      )}
    </div>
  )
}
