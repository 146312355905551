import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import useTranslation from 'next-translate/useTranslation'
import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Arrow } from '..'
import cn from 'clsx'
import { ScrollContext } from '@/providers/ScrollProvider/ScrollProvider'
import { sendEvent } from '@/lib/tracking/events'
import { ChatContext } from '@/lib/chat/ChatProvider'

interface TypeboxProps {
  placeholder?: string
  handleSubmit: (_prompt: string) => void
  disabled?: boolean
  isSubmitAllowed: boolean
  showArrow: boolean
  initialPrompt?: string
  setNewPrompt?: Dispatch<SetStateAction<string>>
  className?: string
}

export const Typebox: FC<TypeboxProps> = ({
  placeholder,
  handleSubmit,
  disabled,
  isSubmitAllowed,
  showArrow,
  initialPrompt,
  setNewPrompt,
  className,
}: TypeboxProps) => {
  const { t } = useTranslation()
  const { isInitialized } = useContext(ChatContext)
  const hasAlwreadyWroteSomething = useRef(false)
  const { handleScrollDown } = useContext(ScrollContext)
  const [prompt, setPrompt] = useState(initialPrompt ?? '')
  useEffect(() => {
    promptRef.current?.focus()
    setPrompt(initialPrompt ?? '')
  }, [initialPrompt])

  const promptRef = useRef<HTMLDivElement>(null)
  const onInputChange = (event: ContentEditableEvent) => {
    const value = event.target.value
    if (!hasAlwreadyWroteSomething.current && value.length === 1) {
      sendEvent({ event: 'Write_Prompt' })
      hasAlwreadyWroteSomething.current = true
    }
    setPrompt(event.target.value)
    setNewPrompt?.(event.target.value)
    if (event.target.value === '<br>') {
      setPrompt('')
      setNewPrompt?.('')
    }
  }

  const submit = (event: FormEvent) => {
    hasAlwreadyWroteSomething.current = false
    event.preventDefault()
    if (isSubmitAllowed) {
      handleSubmit(promptRef.current?.innerHTML ?? '')
      promptRef.current?.blur()
      setPrompt('')
      handleScrollDown()
    }
  }

  const charactersLimit = 800

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Handle enter key
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
      submit(event)
    }

    // Handle maximum length of characters
    if (
      event.currentTarget.innerHTML.length > charactersLimit &&
      ![
        'ArrowRight',
        'ArrowLeft',
        'ArrowUp',
        'ArrowDown',
        'Backspace',
      ].includes(event.key)
    ) {
      event.preventDefault()
    }
  }

  const handleOnClick = () => {
    sendEvent({ event: 'Click_Typebox' })
  }

  const handleOnPaste = (event: React.ClipboardEvent) => {
    event.preventDefault()
    const currentText = event.currentTarget.innerHTML
    const clipboardText = event.clipboardData.getData('text/plain')
    // Handle maximum length of characters
    if (currentText.length + clipboardText.length > charactersLimit) {
      const pastePart = clipboardText.slice(
        0,
        charactersLimit - currentText.length,
      )
      setPrompt(currentText + pastePart)
    } else {
      setPrompt(currentText + clipboardText)
    }
  }

  // Sorry this is not ideal
  // Content editable memoizes the onKeyDown function
  // We therefore create a ref to the handleKeyDown function so that we can update it when isAITyping changes
  const handleKeyDownRef = useRef(handleKeyDown)
  useEffect(() => {
    handleKeyDownRef.current = handleKeyDown
  })

  return (
    <form
      className={cn(
        className,
        'h-auto mb-4 mt-1 mx-2 flex items-end text-black z-20',
      )}
      onSubmit={submit}
    >
      <ContentEditable
        innerRef={promptRef}
        className="h-full w-full mx-5 my-3 focus:outline-none whitespace-normal max-h-40 overflow-y-scroll overscroll-none no-scrollbar"
        // @ts-expect-error placeholder is not on type
        placeholder={placeholder}
        onChange={onInputChange}
        onClick={handleOnClick}
        html={prompt}
        disabled={!isInitialized || disabled}
        onKeyDown={(event) => handleKeyDownRef.current(event)}
        onPaste={handleOnPaste}
      />
      {showArrow && !(prompt === '') && (
        <button
          type="submit"
          className="flex items-center my-3"
          disabled={!isInitialized}
        >
          <span>{t('common:send')}</span>
          <Arrow className="mr-4 ml-1.5 mb-1" />
        </button>
      )}
    </form>
  )
}
