const Logo = ({ ...props }) => (
  <svg
    width="390"
    height="34"
    viewBox="0 0 390 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.9231 31.9836C38.0088 31.9836 33.845 25.2728 33.845 16.9984C33.845 8.72405 38.0018 2.02016 44.9231 2.02016C51.8443 2.02016 56.0012 8.72405 56.0012 17.0053C56.0012 25.2866 51.8373 31.9836 44.9231 31.9836ZM44.9231 0C35.3345 0 27.5542 7.61019 27.5542 16.9984C27.5542 26.3866 35.3276 33.9968 44.9231 33.9968C54.5185 33.9968 62.2919 26.3866 62.2919 16.9984C62.2919 7.61019 54.5116 0 44.9231 0Z"
      fill="black"
    />
    <path
      d="M9.2075 31.2661H6.58866V15.7136H7.71795C11.5631 15.7136 18.0201 16.7652 18.0201 23.8288C18.0201 28.561 14.8124 31.2522 9.21443 31.2522M6.58866 2.77626H9.4777C13.1912 2.77626 15.235 4.66497 15.235 8.08956C15.235 11.8116 13.1704 13.6934 9.08972 13.6934H6.58866V2.77626ZM13.6831 14.5651C18.4635 13.14 20.7914 10.9538 20.7914 7.92352C20.7498 5.52977 19.378 3.3574 17.2303 2.29198C15.3389 1.2819 12.2282 0.776855 8.002 0.776855H0V1.39951C1.30942 2.02908 1.26785 4.13226 1.26785 5.61971V28.4295C1.26785 29.917 1.30942 32.0202 0 32.6497V33.2724H9.87953C11.8818 33.2724 15.7477 33.0372 18.99 31.4528C22.2255 29.8685 23.909 27.4264 23.909 24.2024C23.909 18.1973 18.7476 15.3262 13.6762 14.5859"
      fill="black"
    />
    <path
      d="M63.4507 4.32089H64.0604C65.1342 2.73658 66.984 2.73658 68.4667 2.73658H71.134V28.4106C71.134 29.8981 71.1756 32.0012 69.8661 32.6308V33.2535H77.6949V32.6308C76.3855 32.0012 76.4271 29.8981 76.4271 28.4106V2.73658H79.0944C80.5701 2.73658 82.4269 2.73658 83.4938 4.32781H84.1035V0.757935H63.4507V4.32089Z"
      fill="black"
    />
    <path
      d="M89.446 4.32089H90.0557C91.1227 2.73658 92.9794 2.73658 94.4551 2.73658H97.1224V28.4106C97.1224 29.8981 97.164 32.0012 95.8477 32.6308V33.2535H103.697V32.6308C102.388 32.0012 102.429 29.8981 102.429 28.4106V2.73658H105.097C106.579 2.73658 108.429 2.73658 109.496 4.32781H110.113V0.757935H89.446V4.32089Z"
      fill="black"
    />
    <path
      d="M339.394 4.32089H340.004C341.071 2.73658 342.928 2.73658 344.403 2.73658H347.071V28.4106C347.071 29.8981 347.105 32.0012 345.796 32.6308V33.2535H353.625V32.6308C352.315 32.0012 352.357 29.8981 352.357 28.4106V2.73658H355.024C356.5 2.73658 358.357 2.73658 359.424 4.32781H360.04V0.757935H339.394V4.32089Z"
      fill="black"
    />
    <path
      d="M132.555 31.2679H122.308V17.535H129.742C131.245 17.535 133.386 17.5142 133.975 18.801H134.592V14.2972H133.975C133.393 15.5771 131.239 15.5563 129.742 15.5563H122.308V2.73658H131.19C132.673 2.73658 134.536 2.73658 135.596 4.32781H136.206V0.757935H115.387V1.36675C117.043 2.4391 117.029 4.37624 117.029 5.89827V28.1131C117.029 29.6352 117.029 31.5723 115.387 32.6377V33.2535H137.55V29.7043H136.94C135.929 31.2886 134.024 31.2956 132.541 31.2956"
      fill="black"
    />
    <path
      d="M271.796 31.2679H261.549V17.535H268.983C270.48 17.535 272.627 17.5142 273.209 18.801H273.833V14.2972H273.209C272.627 15.5771 270.473 15.5563 268.983 15.5563H261.549V2.73658H270.431C271.907 2.73658 273.777 2.73658 274.83 4.32781H275.461V0.757935H254.642V1.36675C256.298 2.4391 256.284 4.37624 256.284 5.89827V28.1131C256.284 29.6352 256.284 31.5723 254.642 32.6377V33.2535H276.805V29.7043H276.195C275.184 31.2679 273.272 31.2679 271.796 31.2679Z"
      fill="black"
    />
    <path
      d="M329.91 31.2679H319.664V17.535H327.098C328.594 17.535 330.742 17.5142 331.324 18.801H331.947V14.2972H331.324C330.742 15.5771 328.587 15.5563 327.098 15.5563H319.664V2.73658H328.546C330.021 2.73658 331.885 2.73658 332.945 4.32781H333.555V0.757935H312.75V1.36675C314.412 2.4391 314.398 4.37624 314.398 5.89827V28.1131C314.398 29.6352 314.398 31.5723 312.75 32.6377V33.2535H334.92V29.7043H334.31C333.298 31.2679 331.386 31.2679 329.91 31.2679Z"
      fill="black"
    />
    <path
      d="M183.889 20.7116L188.974 7.4768L194.025 20.7116H183.889ZM202.907 29.1935L191.953 0.85593L191.919 0.765991H189.452L178.547 29.1382C178.159 30.2728 177.065 32.2653 175.79 32.6043V33.2546H181.381V32.6181C180.231 32.0646 179.961 31.1237 180.432 29.8508L183.169 22.6764H194.822L197.524 29.8508C197.995 31.1237 197.711 32.0923 196.574 32.625V33.2615H205.325V32.6181C204.154 32.1822 203.253 29.9891 202.928 29.1935"
      fill="black"
    />
    <path
      d="M166.803 18.6745C166.803 18.6745 168.951 18.9858 168.951 22.0091C168.951 27.9451 163.595 31.9301 158.6 31.9301C154.79 31.9301 151.693 30.3112 149.656 27.2394C147.903 24.6243 147.134 20.9852 147.134 16.9864C147.134 12.7386 147.917 9.12025 149.725 6.52587C151.831 3.53022 154.963 2.00818 159.037 2.00818C163.831 2.00818 167.323 3.80003 169.796 7.32148C170.025 7.64664 170.239 7.98564 170.433 8.33848L171.036 8.2347V0.783632H170.433C169.754 1.42704 168.175 1.44779 167.087 1.33018C166.186 1.21949 165.293 1.03269 164.413 0.783632C162.466 0.257836 160.457 -0.00506114 158.448 0.00185721C154.679 -0.0534896 151 1.12955 147.979 3.3711C143.705 6.60197 140.933 12.0675 140.933 17.2908C140.899 21.8708 142.804 26.2432 146.192 29.3288C149.593 32.3867 154.028 34.054 158.607 33.9987C164.78 33.9987 169.817 31.2382 172.803 26.2224C174.369 23.6073 174.757 21.6286 175.02 18.0657H166.803V18.6745Z"
      fill="black"
    />
    <path
      d="M368.46 20.7116L373.524 7.48372L378.609 20.7116H368.46ZM387.484 29.1935L376.524 0.85593L376.489 0.765991H374.023L363.118 29.1382C362.73 30.2728 361.635 32.2653 360.361 32.6043V33.2546H365.952V32.6181C364.802 32.0646 364.531 31.1237 365.002 29.8508L367.739 22.6764H379.392L382.094 29.8508C382.565 31.1237 382.281 32.0923 381.145 32.625V33.2615H389.895V32.6181C388.724 32.1822 387.824 29.9891 387.498 29.1935"
      fill="black"
    />
    <path
      d="M244.423 1.39442C245.739 2.02399 245.705 3.13785 245.455 3.93346L237.73 26.0653L230.456 4.57687C230.206 3.89195 229.756 2.16236 231.377 1.40134V0.764853H222.627V1.40134C223.694 1.83028 224.532 3.51836 224.976 4.81901L234.675 33.2535H237.391L247.284 4.87436C247.7 3.66364 248.801 1.74726 250.021 1.40826V0.757935H244.423V1.39442Z"
      fill="black"
    />
    <path
      d="M302.423 1.36675C303.462 2.03091 303.455 4.05107 303.455 4.69448V22.4401L288.255 0.8202L288.213 0.764853H281.763V1.37367C283.371 2.4045 283.357 4.29321 283.357 5.76682V29.3169C283.357 29.9603 283.357 31.9805 282.324 32.6446V33.2535H286.502V32.6446C285.463 31.9805 285.47 29.9603 285.47 29.3169V6.59703L304.19 33.2535H305.561V4.69448C305.561 4.05107 305.561 2.03091 306.594 1.36675V0.757935H302.416V1.36675H302.423Z"
      fill="black"
    />
  </svg>
)

export default Logo
