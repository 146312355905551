import cn from 'clsx'
import { ChatProduct } from '@/lib/chat/types'
import { AITextMessage, Recommendation } from '..'
import { useMemo } from 'react'

interface AIBubbleProps {
  text: string
  recommendation?: ChatProduct[]
  showRecommendation: boolean
}

export const AIBubbleGroup = ({
  text,
  recommendation,
  showRecommendation,
}: AIBubbleProps) => {
  const splittedText = useMemo(
    () =>
      text
        .trim()
        .split('. ')
        .filter((message) => message.trim() !== '')
        .map((sentence, i, arr) =>
          i !== arr.length - 1 ? sentence + '.' : sentence,
        ),
    [text],
  )

  return (
    <div className="w-full bg-white p-px my-3 relative">
      <div className="w-full h-full pt-4 px-2 sm:px-4 bg-white">
        {splittedText.map((message) => (
          <div className="flex" key={message}>
            <AITextMessage message={message} />
          </div>
        ))}
        {recommendation && (
          <div
            className={cn(
              'flex justify-start',
              showRecommendation ? '' : 'hidden',
            )}
          >
            <div className="max-w-full">
              <Recommendation
                recommendation={recommendation}
                showRecommendation={showRecommendation}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
