import useTranslation from 'next-translate/useTranslation'
import { FC, useContext } from 'react'
import { sendEvent } from '@/lib/tracking/events'
import { BackArrow, Logo, RefreshIcon } from '..'
import cn from 'clsx'
import { useRouter } from 'next/router'
import PhoneIcon from '../icons/PhoneIcon'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { ChatContext } from '@/lib/chat/ChatProvider'

export interface HeaderProps {
  shouldShowLogoAndRefresh: boolean
}

export const Header: FC<HeaderProps> = ({
  shouldShowLogoAndRefresh,
}: HeaderProps) => {
  const { t } = useTranslation()
  const { handleNewChat } = useContext(ChatContext)
  const searchParams = useSearchParams()
  const previousUrl = searchParams?.get('previous')

  const router = useRouter()
  const onBackArrowClick = () => {
    sendEvent({
      event: 'Click_Back_Arrow',
      data: {
        destination_url:
          typeof router.query.previous === 'string'
            ? router.query.previous
            : '',
      },
    })
    if (previousUrl) {
      router.push(`${previousUrl}?isPlp=true`)
      return
    }
    router.back()
  }

  return (
    <div className="flex justify-center h-20 w-full z-10 bg-white bg-lightBorderGradient ">
      <div className="flex justify-between w-screen sm:w-[620px] lg:w-[730px] xl:w-[800px] px-2 items-start">
        <button
          onClick={onBackArrowClick}
          role="back-button"
          className="py-5 w-20 ml-2"
        >
          <BackArrow />
        </button>
        <div
          className={cn(
            'py-3',
            shouldShowLogoAndRefresh ? 'hidden' : 'animate-header-fade-in',
          )}
        >
          <Logo width="184" height="40" />
          <div className="flex w-full justify-center">
            <span className="text-base">{t('common:virtual_assistant')}</span>
          </div>
        </div>
        <div
          className={cn(
            'py-5 w-20 flex justify-end content-center',
            shouldShowLogoAndRefresh ? 'hidden' : 'animate-header-fade-in',
          )}
        >
          <Link
            href="https://www.bottegaveneta.com/en-gb/customer-care-2"
            target="_blank"
            className="mr-3.5 h-full"
            onClick={() =>
              sendEvent({ event: 'Click_Customer_Care', data: {} })
            }
          >
            <PhoneIcon />
          </Link>
          <button onClick={handleNewChat} role="refresh-button">
            <RefreshIcon className="w-8" />
          </button>
        </div>
      </div>
    </div>
  )
}
