const PhoneIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63696 5C2.63696 3.34315 3.98011 2 5.63696 2C7.29382 2 8.63696 3.34315 8.63696 5C8.63696 6.65685 7.29382 8 5.63696 8V10C6.04768 10 6.44684 9.95048 6.82878 9.85708C7.55887 13.3374 10.2996 16.0781 13.7799 16.8082C13.6865 17.1901 13.637 17.5893 13.637 18H15.637C15.637 16.3431 16.9801 15 18.637 15C20.2938 15 21.637 16.3431 21.637 18C21.637 19.6569 20.2938 21 18.637 21C9.80041 21 2.63696 13.8366 2.63696 5ZM14.6856 14.9359C11.5807 14.514 9.12299 12.0563 8.70108 8.9514C9.87899 8.03666 10.637 6.60685 10.637 5C10.637 2.23858 8.39839 0 5.63696 0C2.87554 0 0.636963 2.23858 0.636963 5C0.636963 14.9411 8.69584 23 18.637 23C21.3984 23 23.637 20.7614 23.637 18C23.637 15.2386 21.3984 13 18.637 13C17.0301 13 15.6003 13.758 14.6856 14.9359Z"
        fill="black"
      />
    </svg>
  )
}

export default PhoneIcon
