import cn from 'clsx'
import { FC } from 'react'

export interface BubbleProps {
  children?: React.ReactNode
  sender: 'AI' | 'User'
  className?: string
}

export const Bubble: FC<BubbleProps> = ({
  children,
  sender,
  className,
}: BubbleProps) => {
  const senderClass =
    sender === 'AI' ? 'bg-gray/70' : 'bg-black text-white ml-2'

  return (
    <div
      className={cn(
        'my-[1px] sm:ml-2 sm:mr-1 px-4 py-2.5 sm:max-w-[500px] break-words text-black',
        senderClass,
        className,
      )}
    >
      {children}
    </div>
  )
}
