const PenIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="14.1631"
        width="6.80147"
        height="4.85819"
        rx="2"
        transform="rotate(31.3717 14.1631 0)"
        fill="black"
      />
      <path
        d="M12.8121 3.17621L17.7655 6.19638L7.74972 22.6231L3.32798 24.3031L2.79638 19.6029L12.8121 3.17621Z"
        stroke="black"
      />
    </svg>
  )
}

export default PenIcon
