import cn from 'clsx'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Chat, TextMessage } from '@/lib/chat/types'
import { AITypedBubble, ErrorIcon, Loader } from '..'
import { AiMessageBox } from './AiMessageBox'
import { UserMessageBox } from './UserMessageBox'
import { ScrollContext } from '@/providers/ScrollProvider/ScrollProvider'
import { IA_CONTAINER_MESSAGE_CLASSNAME } from '@/constants'
import useTranslation from 'next-translate/useTranslation'
import { ChatContext } from '@/lib/chat/ChatProvider'

interface MessagesBoxProps {
  chat: Chat
}

export const MessagesBox = ({ chat }: MessagesBoxProps) => {
  const { messageContainerRef } = useContext(ScrollContext)
  const { isAiTyping, isUserEditing, assistantIsLoading, hasError } =
    useContext(ChatContext)
  const chatLength = chat.messages.length
  const { t } = useTranslation()
  const [reassuringState, setReassuringState] = useState(false)
  const timoutRef = useRef(0)
  const showPenIcon = (message: TextMessage) => {
    return (
      message.editable &&
      chat.messages[chatLength - 1].sender === 'AI' &&
      !isAiTyping &&
      !isUserEditing
    )
  }

  const recommendation = (messageIndex: number) => {
    const nextMessage = chat.messages[messageIndex + 1]
    if (typeof nextMessage === 'undefined') {
      return undefined
    } else if (nextMessage.type === 'recommendation') {
      return nextMessage.recommendation
    }

    return undefined
  }

  const showRecommendation = useCallback(
    (index: number) => {
      return !(isAiTyping && index === chatLength - 2)
    },
    [isAiTyping, chatLength],
  )

  useEffect(() => {
    if (assistantIsLoading) {
      timoutRef.current = window.setTimeout(() => {
        setReassuringState(true)
      }, 5000)
    } else {
      clearTimeout(timoutRef.current)
      setReassuringState(false)
    }
  }, [assistantIsLoading])

  return (
    <div className="flex justify-center mt-28">
      <div
        className="w-screen sm:w-[620px] lg:w-[730px] xl:w-[800px]"
        ref={messageContainerRef}
      >
        <AITypedBubble />
        {chat.messages.map((message, index) => {
          if (message.type !== 'text') {
            return null
          }
          return (
            <div
              className={cn({
                [IA_CONTAINER_MESSAGE_CLASSNAME]: message.sender === 'AI',
                'flex justify-end my-1': message.sender === 'User',
              })}
              key={`${message.type}-${message.sender}-${index}`}
            >
              {message.sender === 'AI' && (
                <AiMessageBox
                  text={message.text}
                  recommendation={recommendation(index)}
                  showRecommendation={showRecommendation(index)}
                />
              )}
              {message.sender === 'User' && (
                <UserMessageBox
                  message={message}
                  index={index}
                  isUserEditing={isUserEditing}
                  showPenIcon={showPenIcon}
                />
              )}
            </div>
          )
        })}
        {!hasError && assistantIsLoading && (
          <div className="mx-2 px-2 flex items-center">
            {!hasError && <Loader />}
            {!hasError && reassuringState && (
              <div className="ml-2 text-xs">
                {t('common:loader_reassuring')}
              </div>
            )}
          </div>
        )}
        {hasError && (
          <div className="mx-2 px-2 flex items-center">
            <>
              <ErrorIcon />
              <div className="ml-2 text-xs text-error">
                {t('common:loader_error')}
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  )
}
