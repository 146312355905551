const ErrorIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.6528"
        cy="9.99976"
        r="8.98999"
        stroke="#FF0000"
        strokeWidth="1.5"
      />
      <path
        d="M10.6841 13.8711C11.1641 13.8711 11.5481 14.2551 11.5481 14.7351C11.5481 15.1991 11.1641 15.5671 10.6841 15.5671C10.2201 15.5671 9.85207 15.1991 9.85207 14.7351C9.85207 14.2551 10.2201 13.8711 10.6841 13.8711ZM9.99607 12.2711V4.38315H11.3721V12.2711H9.99607Z"
        fill="#FF0000"
      />
    </svg>
  )
}

export default ErrorIcon
