import { FC, useContext } from 'react'
import cn from 'clsx'
import { LightPenIcon, PenIcon } from '..'
import { ChatContext } from '@/lib/chat/ChatProvider'

export interface EditButtonProps {
  isUserEditing: boolean
  className?: string
}

export const EditButton: FC<EditButtonProps> = ({
  isUserEditing,
  className,
}: EditButtonProps) => {
  const { setIsUserEditing } = useContext(ChatContext)
  const onClick = () => {
    setIsUserEditing((prevState) => !prevState)
  }
  return (
    <button
      className={cn(
        className,
        'rounded-full h-11 w-11 flex items-center justify-center',
      )}
      onClick={onClick}
    >
      {isUserEditing ? <LightPenIcon /> : <PenIcon />}
    </button>
  )
}
