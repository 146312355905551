import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { IntroTextMessage } from '../IntroTextMessage'

export const AITypedBubble = () => {
  const { t } = useTranslation()
  const splittedtext = useMemo(
    () => [
      { text: t('common:ai_greeting_bubble_1'), prompt: undefined },
      { text: t('common:ai_greeting_bubble_2'), prompt: undefined },
      {
        text: t('common:ai_greeting_bubble_3'),
        prompt: t('common:first_clickable_prompt'),
      },
    ],
    [t],
  )

  return (
    <div className="w-full bg-white p-px  my-3 relative">
      <div className="w-full h-full pt-4 px-2 sm:px-4 bg-white">
        {splittedtext.map((message, index) => (
          <div className="flex" key={index}>
            <IntroTextMessage message={message} />
          </div>
        ))}
      </div>
    </div>
  )
}
