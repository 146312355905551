const Arrow = ({ ...props }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 7.27027V2L17 9.5L1 17V12.2273L7 9.5L1 7.27027Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default Arrow
