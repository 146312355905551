import { FC } from 'react'
import cn from 'clsx'

export interface ButtonProps {
  innerText: string
  onClick?: () => void
  className?: string
}

export const Button: FC<ButtonProps> = ({
  innerText,
  onClick,
  className,
}: ButtonProps) => {
  return (
    <button
      className={cn(
        'py-5 px-6 flex items-center bg-black text-white hover:bg-darkgray justify-center h-10 font-bold text-base',
        className,
      )}
      onClick={onClick}
    >
      {innerText}
    </button>
  )
}
