import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { ChatProduct } from '@/lib/chat/types'
import { sendEvent } from '@/lib/tracking/events'
import { capitalize } from '@/utils/capitalize'
import { Button } from '..'
import Link from 'next/link'

interface ProductCardProps {
  product: ChatProduct
}

export const ProductCard = ({ product }: ProductCardProps) => {
  const { t } = useTranslation()
  const handleProductClick = () => {
    sendEvent({
      event: 'Click_Product',
      data: {
        product_name: product.title,
        destination_url: product.url,
      },
    })
  }
  return (
    <Link
      onClick={handleProductClick}
      href={`${product.url}?utm_source=virtual-assistant&utm_medium=virtual-assistant.bottegaveneta`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-[250px] mb-2 pr-0.5 pb-3 flex flex-col">
        <div className="w-full flex bg-white max-h-[291px]">
          <Image
            src={product.imageUrl}
            height={280}
            width={250}
            alt={product.title}
            className="object-cover"
            quality={80}
            unoptimized={true}
            priority={true}
          />
        </div>
        <div className="py-2 w-2/3 h-16">
          <span
            className="text-base truncate-product-name"
            title={capitalize(product.title)}
          >
            {capitalize(product.title)}
          </span>
        </div>
        <Button className="w-full" innerText={t('common:view_product')} />
      </div>
    </Link>
  )
}
