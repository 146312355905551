import { FC, useContext, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button, EditButton, Typebox } from '..'
import { ChatContext } from '@/lib/chat/ChatProvider'

interface EditableBubbleProps {
  messageText: string
}

export const EditableBubble: FC<EditableBubbleProps> = ({ messageText }) => {
  const { t } = useTranslation()
  const [newPrompt, setNewPrompt] = useState(messageText)
  const { setIsUserEditing, handleUpdateMessage } = useContext(ChatContext)
  const onCancel = () => {
    setIsUserEditing((prevState) => !prevState)
  }
  const onClick = () => {
    handleUpdateMessage(newPrompt)
  }
  return (
    <div className="mb-4">
      <div className="flex justify-end items-center">
        <div>
          <Typebox
            isSubmitAllowed={true}
            handleSubmit={handleUpdateMessage}
            initialPrompt={messageText}
            showArrow={false}
            setNewPrompt={setNewPrompt}
            className="border-2 border-black"
          />
        </div>
        <EditButton isUserEditing={true} className="mb-4" />
      </div>
      <div className="flex justify-end mx-2">
        <button
          className="text-black underline underline-offset-2 hover:no-underline w-32"
          onClick={onCancel}
        >
          <span>{t('common:cancel')}</span>
        </button>
        <Button
          innerText={t('common:update')}
          className="w-36"
          onClick={onClick}
        />
        <div className="w-11"></div>
      </div>
    </div>
  )
}
