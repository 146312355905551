const DropArrow = ({ ...props }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.53648 6.06838L3.68848 6.06838L0.424482 1.25238L1.19248 0.740379L4.10448 4.99638L7.01648 0.740379L7.78448 1.25238L4.53648 6.06838Z"
        fill="black"
      />
    </svg>
  )
}

export default DropArrow
