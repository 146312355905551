import { useKeenSlider } from 'keen-slider/react'
import { ChatProduct } from '@/lib/chat/types'
import { WheelControls } from '@/lib/keen-slider/wheels-control'
import { ProductCard } from '..'
import 'keen-slider/keen-slider.min.css'

interface RecommendationProps {
  recommendation: ChatProduct[]
  showRecommendation?: boolean
}

export const Recommendation = ({
  recommendation,
  showRecommendation,
}: RecommendationProps) => {
  const [sliderRef] = useKeenSlider(
    {
      slides: {
        perView: 'auto',
      },
      rubberband: false,
    },
    [WheelControls],
  )

  return (
    <div
      ref={
        showRecommendation !== undefined && showRecommendation
          ? sliderRef
          : null
      }
      className="keen-slider max-w-full pt-0.5 my-[1px] sm:ml-2 sm:mr-1"
    >
      {recommendation.map((product, index) => (
        <div
          className="keen-slider__slide"
          style={{ maxWidth: 250, minWidth: 250 }}
          key={`${product.title}-${index}`}
        >
          <ProductCard product={product} />
        </div>
      ))}
    </div>
  )
}
