const LightPenIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="16.8291"
        y="6.10352e-05"
        width="8.08161"
        height="5.77258"
        rx="2"
        transform="rotate(31.3717 16.8291 6.10352e-05)"
        fill="#E3E3E3"
      />
      <path
        d="M15.2753 3.98832L20.8947 7.41462L9.06763 26.8121C9.05208 26.8211 9.03546 26.8307 9.01783 26.8408C8.88204 26.919 8.68597 27.0305 8.44681 27.1626C7.96748 27.4272 7.31981 27.7716 6.64 28.0953C5.955 28.4214 5.26102 28.7149 4.68343 28.8884C4.39357 28.9754 4.15871 29.0242 3.98361 29.0388C3.90715 29.0452 3.85669 29.0437 3.82657 29.0413C3.81321 29.02 3.79432 28.9863 3.77169 28.9355C3.70527 28.7865 3.64011 28.5656 3.5838 28.2722C3.47172 27.6883 3.41658 26.9372 3.39652 26.173C3.37663 25.4152 3.39181 24.6731 3.4122 24.1183C3.42237 23.8415 3.43378 23.6128 3.44261 23.4539C3.4439 23.4307 3.44514 23.409 3.4463 23.3889L15.2753 3.98832Z"
        stroke="#E3E3E3"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default LightPenIcon
