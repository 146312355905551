import { useRef } from 'react'
import { Bubble } from '..'

interface AITextMessageProps {
  message: string
}

export const AITextMessage = ({ message }: AITextMessageProps) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  const spantext = useRef<HTMLSpanElement>(null)

  const width =
    spantext.current?.offsetWidth !== undefined
      ? spantext.current.offsetWidth + 2
      : undefined

  return (
    <Bubble sender={'AI'}>
      <div style={{ width: width }}>
        <span
          ref={spantext}
          dangerouslySetInnerHTML={{
            __html: message.replace(
              urlRegex,
              '<a href="$1" target="_blank">$1</a>',
            ),
          }}
        ></span>
      </div>
    </Bubble>
  )
}
