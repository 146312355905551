const Cross = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.78687 5.62622L17.2 17.0393"
        stroke="#1B1B1B"
        strokeWidth="2"
      />
      <path
        d="M5.78687 17.2002L17.2 5.78711"
        stroke="#1B1B1B"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Cross
