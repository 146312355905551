import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Bubble } from '..'
import { ChatContext } from '@/lib/chat/ChatProvider'
import { sendEvent } from '@/lib/tracking/events'

interface IntroTextMessageProps {
  message: {
    text: string
    prompt: string | undefined
  }
}

export const IntroTextMessage = ({ message }: IntroTextMessageProps) => {
  const spantext = useRef<HTMLSpanElement>(null)
  const { setInitialPrompt, assistantIsLoading } = useContext(ChatContext)
  const [width, setWidth] = useState<number | undefined>(undefined)
  spantext.current?.offsetWidth !== undefined
    ? spantext.current.offsetWidth + 2
    : undefined

  useEffect(() => {
    setWidth(
      spantext.current?.offsetWidth !== undefined
        ? spantext.current.offsetWidth + 2
        : undefined,
    )
  }, [])

  const handleClick = useCallback(() => {
    if (!assistantIsLoading) {
      setInitialPrompt(message.prompt ?? '')
      sendEvent({
        event: 'Click_Prompt_Example',
      })
    }
  }, [assistantIsLoading, message.prompt, setInitialPrompt])

  return (
    <Bubble sender={'AI'}>
      <div style={{ width: width }}>
        <span ref={spantext}>
          {message.text}
          {message.prompt === undefined ? null : (
            <span
              role="button"
              className="underline underline-offset-2"
              onClick={handleClick}
              onKeyDown={handleClick}
              tabIndex={0}
            >
              {message.prompt}
            </span>
          )}
        </span>
      </div>
    </Bubble>
  )
}
