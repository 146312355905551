import Trans from 'next-translate/Trans'
import { DropArrow, Modal } from '..'
import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'
import { sendEvent } from '@/lib/tracking/events'
import { ScrollContext } from '@/providers/ScrollProvider/ScrollProvider'
import { getBannerCookie } from '@/utils/getBannerCookie'

export const LegalMentions = () => {
  const [isBannerOpen, setIsBannerOpen] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { privacyContainerRef } = useContext(ScrollContext)

  useEffect(() => {
    const cookie = getBannerCookie(document.cookie)
    setIsBannerOpen(cookie !== 'false')
  }, [])

  const handlePersonalDataClick = () => {
    setIsModalOpen(true)
    sendEvent({
      event: 'Click_Sensitive_Data',
      data: null,
    })
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleArrowClick = () => {
    setIsBannerOpen(!isBannerOpen)
    document.cookie = `privacyBannerOpened=${!isBannerOpen}; path=/`
  }

  return (
    <div
      ref={privacyContainerRef}
      className="w-full text-center z-20  text-xxs md:text-xs py-3 flex justify-center bg-white"
    >
      <div className="w-screen sm:w-[620px] lg:w-[730px] xl:w-[800px] px-2 flex pl-6 lg:pl-0">
        <span className={isBannerOpen ? '' : 'truncate-legal-mentions'}>
          <Trans
            i18nKey={'common:legal_mentions'}
            components={[
              <button
                className="underline"
                key={0}
                onClick={handlePersonalDataClick}
              />,
              <Link
                className="underline"
                href="https://www.bottegaveneta.com/en-gb/legal-pages/privacy-policy/privacy-policy.html"
                target="_blank"
                onClick={() => {
                  sendEvent({
                    event: 'Click_Privacy_Policy',
                    data: {
                      destination_url:
                        'https://www.bottegaveneta.com/en-en/legal-pages/privacy-policy/privacy-policy.html',
                    },
                  })
                }}
                key={1}
              />,
            ]}
          />
        </span>
        <button onClick={handleArrowClick}>
          <div className="w-6 h-full flex justify-center mt-0.5">
            <DropArrow className={isBannerOpen ? 'rotate-180' : ''} />
          </div>
        </button>
      </div>
      {isModalOpen && <Modal closeModal={closeModal} />}
    </div>
  )
}
