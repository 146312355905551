import { ChatProduct } from '@/lib/chat/types'
import { AIBubbleGroup } from '../AIBubble'

type AIMessagePropTypes = {
  text: string
  recommendation: ChatProduct[] | undefined
  showRecommendation: boolean
}
export const AiMessageBox = ({
  text,
  recommendation,
  showRecommendation,
}: AIMessagePropTypes) => {
  return (
    <AIBubbleGroup
      text={text}
      recommendation={recommendation}
      showRecommendation={showRecommendation}
    />
  )
}
