import useTranslation from 'next-translate/useTranslation'
import { Cross } from '..'

export const Modal = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation()
  return (
    <div className="fixed top-0 w-screen h-screen bg-black/50 flex justify-center items-center">
      <div className="bg-white w-screen sm:w-[500px] mx-4 flex">
        <div className="pl-4 py-8 text-left text-black">
          {t('common:sensitive_personal_data')}
        </div>
        <div className="p-1">
          <button onClick={closeModal}>
            <Cross />
          </button>
        </div>
      </div>
    </div>
  )
}
