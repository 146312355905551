const RefreshIcon = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="9" stroke="black" strokeWidth="2" />
      <rect
        x="18.665"
        y="8.40161"
        width="5.58699"
        height="6.70439"
        transform="rotate(29.9692 18.665 8.40161)"
        fill="white"
      />
      <path
        d="M19.5 10L11 7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.5542 9.81116L21.4551 1"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default RefreshIcon
