export interface TextMessage {
  type: 'text'
  sender: 'User' | 'AI'
  text: string
  editable: boolean
}

export interface ChatProduct {
  title: string
  description: string
  imageUrl: string
  url: string
}

export interface RecommendationMessage {
  type: 'recommendation'
  sender: 'AI'
  recommendation: ChatProduct[]
}

export type Message = TextMessage | RecommendationMessage

export const isTextMessage = (message: Message): message is TextMessage =>
  message.type === 'text'
export interface Chat {
  messages: Message[]
  previousProductsIds: string[]
}

export interface TextMessagesChat {
  messages: TextMessage[]
  previousProductsIds: string[]
}
